<script setup lang="ts">
import { Icon } from "@/components/Ui";
import { ref, onMounted } from "vue";
import { type TTransitions } from "~/types/transitions.types";

interface Props {
  title?: string;
  position:
    | "top-center"
    | "top-right"
    | "top-left"
    | "bottom-center"
    | "bottom-right"
    | "bottom-left"
    | "center-center";
  closable?: boolean;
  mode?: "light" | "dark";
  transition?: TTransitions;
  fixed?: boolean;
  fullscreen?: boolean;
  backdrop?: boolean;
  backdropClosed?: boolean;
  modalClass?: string;
  island?: boolean;
  closeSize?: number;
  wrapperClass?: string;
}

const emit = defineEmits(["close", "click:backdrop"]);
const props = defineProps<Props>();
const showRoot = ref<boolean>(true);
const transition = ref<any>("");

const closeStyle = computed(() => {
  return {
    width: `${props.closeSize || 32}px`,
    height: `${props.closeSize || 32}px`,
  };
});
const onCloseHandler = () => {
  showRoot.value = !showRoot.value;
  setTimeout(() => {
    emit("close");
  }, 300);
};
const onClickBackdrop = () => {
  emit("click:backdrop");
  if (props.backdropClosed) {
    onCloseHandler();
  }
  return;
};

const resizeHandler = () => {
  const vh = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  );
  document.documentElement.style.setProperty("--window-height", `${vh}px`);
};

onUnmounted(() => {
  if (props.fullscreen) {
    window.removeEventListener("resize", resizeHandler);
  }

  if (props.fixed) {
    document.documentElement.classList.remove("no-scrolled");
  }
});
onMounted(() => {
  if (props.fullscreen) {
    window.addEventListener("resize", resizeHandler);
  }

  // setTimeout(() => {
  //   showRoot.value = !showRoot.value;
  // }, 300);

  if (props.fixed) {
    document.documentElement.classList.add("no-scrolled");
  }

  const setTransition = (position: string) => {
    switch (position) {
      case "top-center":
        return props.transition || "fade-slide-down";
      case "top-right":
        return props.transition || "fade-slide-down";
      case "top-left":
        return props.transition || "fade-slide-down";
      case "bottom-center":
        return props.transition || "fade-slide-up";
      case "bottom-right":
        return props.transition || "fade-slide-up";
      case "bottom-left":
        return props.transition || "fade-slide-up";
      case "center-center":
        return props.transition || "fade-slide-up";
    }
  };
  transition.value = setTransition(props.position);
});
</script>

<template>
  <div
    :class="[modal.root, modal[`root-island-${props.island || false}`]]"
    style="height: var(--window-height)"
  >
    <transition :name="transition">
      <div
        v-if="showRoot"
        :class="[
          modal.wrapper,
          modal[`root-${props.position}`],
          modal[`mode-${props.mode || 'light'}`],
          modal[`wrapper-fullscreen-${props.fullscreen || false}`],
          props.modalClass,
        ]"
      >
        <!--      Header-->
        <div
          v-if="props.closable || props.title || $slots.title"
          :class="modal.header"
        >
          <span
            v-if="props.title || $slots.title"
            :class="modal.title"
          >
            <slot
              v-if="$slots.title"
              name="title"
            />
            <template v-if="props.title">{{ props.title }}</template>
          </span>
          <span
            v-if="props.closable && !$slots.close"
            :class="[modal.close, modal[`close-${props.mode || 'light'}`]]"
            :style="closeStyle"
            @click="onCloseHandler"
          >
            <Icon name="close" />
          </span>
          <div
            v-if="$slots.close"
            class="ltr:ml-auto rtl:mr-auto"
            @click="onCloseHandler"
          >
            <slot name="close" />
          </div>
        </div>
        <slot />
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="showRoot && props.backdrop"
        :class="modal.backdrop"
        @click="onClickBackdrop"
      />
    </transition>
  </div>
</template>

<style lang="scss" module="modal">
.root {
  @apply fixed z-[2000] min-w-[320px] font-sans font-normal;

  &-island-true {
    @apply w-fit h-fit;
  }

  &-island-false {
    @apply w-full h-screen;
  }

  &-top-center {
    @apply top-0 bottom-0 right-0 left-0;

    @screen md {
      @apply bottom-auto mx-auto;
    }
    &-fulscreen-true {
      @apply m-0;
    }

    &-fulscreen-false {
      @apply m-5;
    }
  }

  &-top-right {
    @apply top-0 bottom-0 right-0 left-0;

    @screen md {
      @apply bottom-auto ltr:left-auto rtl:right-auto m-5;
    }
  }

  &-top-left {
    @apply top-0 bottom-0 right-0 left-0;

    @screen md {
      @apply bottom-auto ltr:right-auto rtl:left-auto m-5;
    }
  }

  &-bottom-center {
    @apply top-0 bottom-0 right-0 left-0;

    @screen md {
      @apply top-auto mx-auto m-5;
    }
  }

  &-bottom-right {
    @apply top-0 bottom-0 right-0 left-0;

    @screen md {
      @apply top-auto left-auto m-5;
    }
  }

  &-bottom-left {
    @apply top-0 bottom-0 right-0 left-0;

    @screen md {
      @apply top-auto right-auto m-5;
    }
  }

  &-center-center {
    @apply top-0 bottom-0 right-0 left-0;

    @screen md {
      @apply m-auto;
    }
  }
}

.wrapper {
  @apply fixed z-[1001] min-w-[320px] flex flex-col;

  &-fullscreen {
    &-true {
      @apply w-full h-full;
    }

    &-false {
      @apply p-4;

      @screen md {
        @apply w-fit h-fit;
        @apply rounded-[20px];
        box-shadow:
          0px 275px 110px rgba(0, 0, 0, 0.01),
          0px 155px 93px rgba(0, 0, 0, 0.05),
          0px 69px 69px rgba(0, 0, 0, 0.09),
          0px 17px 38px rgba(0, 0, 0, 0.1),
          0px 0px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.header {
  @apply w-full flex items-center justify-between mb-4;
}

.title {
  @apply ltr:mr-auto rtl:ml-auto font-bold text-xl;
}

.close {
  @apply rounded-full ltr:ml-auto rtl:mr-auto flex items-center justify-center cursor-pointer transition-all;

  &-light {
    @apply hover:bg-neutral-100;
  }

  &-dark {
    @apply hover:bg-neutral-800;
  }

  & svg {
    @apply w-full h-full p-1;
  }
}

.mode {
  &-light {
    @apply bg-white text-neutral-900;
  }

  &-dark {
    @apply bg-neutral-900 text-white;
  }
}

.backdrop {
  @apply fixed z-[1000] top-0 bottom-0 right-0 left-0 bg-black opacity-50;
}
</style>
