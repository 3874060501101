<script setup lang="ts">
import { Modal, Button } from "@/components/Ui";
import { onSocial } from "@/helpers/events";
import { useRoute } from "vue-router";
import { useUserStore } from "@/store/user";
import { useDictionaryStore } from "@/store/dictionary";
import config from "@/config/env";
import { TCountryResource } from "~/types/api/TCountryResource";

const route = useRoute();
const { t } = useI18n();
const countryCode = ref<string>("");
const isShowCountryList = ref<boolean>(false);
const userStore = useUserStore();

const dictionaryStore = useDictionaryStore();

const headers = useRequestHeaders();

const loadCountries = async () => {
  const res: { data: TCountryResource[] } = await $fetch(
    config.apiUrl + "/dictionary/countries",
  );
  return res.data;
};

const { data: isOpen } = useAsyncData(async () => {
  let code = headers["cf-ipcountry"];
  if (!process.server) {
    code = await userStore.loadCountryCode();
  }

  if (!code) {
    return false;
  }

  const countries = await loadCountries();

  const blockedCountries = countries
    .filter((country) => country.block)
    .map((country) => country.iso2);

  const blockedCountriesCodes = new Set(blockedCountries);
  const isBlockedCountry = blockedCountriesCodes.has(code);
  const isBot = isUserAgentBot(headers["user-agent"]);
  const isPstPath = route.path.endsWith("/pst");

  return isBlockedCountry && !isBot && !isPstPath;
});

watch(
  [() => userStore.countryCode, () => dictionaryStore.blockedCountriesCodes],
  () => {
    if (route.path.endsWith("/pst")) return;
    if (!userStore.countryCode) return;
    if (!dictionaryStore.blockedCountriesCodes.has(userStore.countryCode)) {
      return;
    }
    countryCode.value = userStore.countryCode;
    isOpen.value = true;
  },
);

const blockedCountryCodes = computed(
  () => dictionaryStore.blockedCountriesCodes,
);

const isUserAgentBot = (userAgent: string): boolean => {
  return /bot|crawl|spider|slurp|fetch/i.test(userAgent);
};
</script>
<template>
  <Modal
    v-if="isOpen"
    :fullscreen="true"
    position="center-center"
    modal-class="w-full !bg-black !bg-opacity-40 overflow-hidden overflow-y-auto"
  >
    <!-- Mobile version -->
    <div class="sm:hidden bg-modal-mobile flex flex-col justify-end p-2 h-full">
      <div class="flex flex-none bg-white p-4 flex-col rounded-6">
        <div
          class="flex flex-none text-[#15181E] text-center text-5 font-medium leading-6 mb-4"
        >
          {{
            t("unavailable-country.title", {
              c: t(`unavailable-country-list.${countryCode}`),
            })
          }}
        </div>
        <p class="text-center mb-6">
          {{
            t("unavailable-country.text", {
              c: t(`unavailable-country-list.${countryCode}`),
            })
          }}
        </p>
        <div class="flex flex-none flex-col">
          <div class="flex flex-none mb-2">
            <Button
              color="grey"
              size="small"
              class="w-full"
              @click="isShowCountryList = true"
            >
              {{ t("unavailable-country.button.list") }}
            </Button>
          </div>
          <div class="flex flex-none">
            <Button
              size="small"
              class="w-full"
              @click="isOpen = !isOpen"
            >
              {{ t("unavailable-country.button.submit") }}
            </Button>
          </div>
        </div>
      </div>
    </div>

    <!-- Desktop Support btn -->
    <div
      class="ml-auto mb-8 md:mb-0 fixed sm:flex hidden right-4 top-5 bg-[#E3F3FE] rounded-full w-11 h-11"
    >
      <Button
        color="custom"
        size="custom"
        icon="headphone"
        icon-class="w-6 h-6"
        class="w-8 h-8 text-[#194BFB] m-auto"
        @click="onSocial('', 'telegram', true, $i18n.locale)"
      />
    </div>

    <!-- Desktop version -->
    <div
      class="flex-col items-center text-center justify-center max-w-[27.5rem] m-auto sm:flex hidden bg-white rounded-[6px] p-10"
    >
      <div class="flex flex-col gap-10">
        <div class="flex flex-none">
          <nuxt-img
            src="/img/geoblock-map.png"
            format="webp"
            class="w-full"
          />
        </div>
        <div
          class="text-[#15181E] text-[1.5rem] leading-[2rem] font-bold flex flex-none"
        >
          {{
            t("unavailable-country.title", {
              c: t(`unavailable-country-list.${countryCode}`),
            })
          }}
        </div>
        <div class="flex flex-none text-[#15181E] text-[1rem] leading-5">
          {{
            t("unavailable-country.text", {
              c: t(`unavailable-country-list.${countryCode}`),
            })
          }}
        </div>
        <div class="flex flex-none flex-col">
          <div class="mb-3">
            <Button
              size="small"
              class="w-full"
              @click="isOpen = !isOpen"
            >
              {{ t("unavailable-country.button.submit") }}
            </Button>
          </div>

          <div>
            <Button
              color="grey"
              size="small"
              class="w-full text-[#6E7687] font-medium text-base"
              @click="isShowCountryList = true"
            >
              {{ t("unavailable-country.button.list") }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal
    v-if="isShowCountryList"
    position="center-center"
    closable
    :title="t('unavailable-country.button.list')"
    class="!z-[3000]"
    modal-class="w-full overflow-y-auto md:max-w-[37.5rem]"
    @close="isShowCountryList = false"
  >
    <ul class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-2">
      <li
        v-for="code in blockedCountryCodes"
        :key="code"
        class="text-base"
      >
        {{ t(`unavailable-country-list.${code}`) }}
      </li>
    </ul>
  </Modal>
</template>
<style scoped>
.bg-modal-mobile {
  background: rgba(21, 25, 29, 0.4);
}
</style>
